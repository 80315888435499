
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItemDivider,
  IonList,
  IonPage,
  IonRow, IonSearchbar, isPlatform, onIonViewWillEnter
} from "@ionic/vue";
import {useStore} from "vuex";
import ExitDeliveryListComponent from "@/app/view/delivery/list/ExitDeliveryListItemComponent.vue";
import _, {map} from "lodash";
import {ExitDelivery} from "@/domain/model/delivery/delivery.model";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useStockApi} from "@/domain/service/api/stock.api";
import {formatDate} from "@/domain/helper/date.helper";
import {NButton, NDataTable, NSpace} from "naive-ui";
import router from "@/app/router";

export default defineComponent({
  name: "ExitDeliveryRecord",
  components: {
    IonCard,
    IonPage,
    IonContent,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonSearchbar,
    ExitDeliveryListComponent,
    IonItemDivider,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();

    const isEmpty = computed(() => store.getters.allExitDeliveries?.length === 0);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    const data = ref<any>([]);
    const searchWord = ref('');
    // const maxResults = ref(25);
    const searchExitDelivery = (exitDelivery: ExitDelivery, word: string): boolean => formatDate(exitDelivery.date, "DD-MM-YYYY").includes(word.toLowerCase()) ||
        exitDelivery.indexPosition.includes(word);

    const filterExitDeliveries = (exitDeliveries: ExitDelivery[], word: string): ExitDelivery[] => _.filter(exitDeliveries, (exitDelivery: ExitDelivery) => searchExitDelivery(exitDelivery, word));
    const sortExitDeliveries = (exitDeliveries: ExitDelivery[]): ExitDelivery[] => _.orderBy(exitDeliveries, ['date'], ['desc']);

    const exitDeliveries = computed<ExitDelivery[]>(() => sortExitDeliveries(filterExitDeliveries(store.getters.allExitDeliveries, searchWord.value)));

    const sentDate = (date: number) => formatDate(date, "DD-MM-YYYY");
    const sentHour = (date: number) => formatDate(date, "HH:mm")

    const goDetailsExitDelivery = async (id: string) => {
      await router.push({
        name: 'detailsExitDelivery',
        params: {id: id}
      });
    }
    const replaceData = () => {
      data.value =
          map(exitDeliveries.value, (exitDelivery: ExitDelivery) => {
            return {
              id: exitDelivery.id,
              date: sentDate(exitDelivery.date),
              time: sentHour(exitDelivery.date),
              number: exitDelivery.indexPosition,
            };
          });
    };

    watch(exitDeliveries, () => replaceData(), {immediate: true});

    onIonViewWillEnter(() => {
      // await useDeliveryApi().fetchAllExit();
      useDeliveryApi().fetchAll();
      useMaterialApi().fetchAll();
      useStockApi().fetchAll();
    });

    return {
      data,
      columns: [
        {
          title: "Fecha",
          key: "date",
          sorter: 'default'
        },
        {
          title: "Hora",
          key: "time",
          sorter: 'default'
        },
        {
          title: "Número de albarán",
          key: "number",
          sorter: 'default'
        },
        {
          title: 'Acciones',
          key: 'actions',
          render(row: any): any {
            return h(
                NButton,
                {
                  size: 'small',
                  onClick: () => goDetailsExitDelivery(row.id),
                },
                {default: () => 'Detalles'},
            );
          },
        },
      ],
      searchWord,
      isTablet,
      exitDeliveries,
      isEmpty,
    };
  }
})
