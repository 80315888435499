
import {computed, defineComponent, ref} from "vue";
import {IonButton, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRow, isPlatform} from "@ionic/vue";
import router from "@/app/router";
import {ExitDelivery} from "@/domain/model/delivery/delivery.model";
import {formatDate} from "@/domain/helper/date.helper";

export default defineComponent({
  name: "ExitDeliveryListComponent",
  components: {
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonGrid,
    IonButton,
  },
  props: {
    exitDelivery: null,
  },
  setup(prop: any) {
    // const exitDeliveryRef = ref<ExitDelivery>(prop.exitDelivery);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    const sentDate = formatDate(prop.exitDelivery.date, "DD-MM-YYYY");
    const sentHour = formatDate(prop.exitDelivery.date, "HH:mm")

    const timeStampHour = (date: number) => {
      const newDate = new Date(date);
      return formatDate(newDate, 'HH:mm');
    }

    const goDetailsExitDelivery = async () => {
      await router.push({
        name: 'detailsExitDelivery',
        params: {id: prop.exitDelivery.id}
      });
    }

    return {
      isTablet,
      // exitDeliveryRef,
      sentDate,
      sentHour,
      timeStampHour,
      goDetailsExitDelivery,
    };
  }
})
